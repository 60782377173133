import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Loader from '../../layouts/loader/Loader';
import gruposService from '../../services/http/gruposService';
import ActivityCard from './ActivityCard';

const FormularioActividades = () => {
  const { empresaId, id } = useParams();
  const [grupoDataa, setGrupoDataa] = useState([]);

  useEffect(() => {
    const fetchGrupoData = async () => {
      const grupoData = await gruposService.consultarActividadesGrupo({
        headquarter: empresaId,
        id,
      });
      setGrupoDataa(grupoData);
    };
    fetchGrupoData();
  }, []);

  return (
    <>
      {!grupoDataa.length > 0 ? (
        <Loader />
      ) : (
        <div className="mt-3">
          {grupoDataa.map((a) => (
            <ActivityCard actividad={a} />
          ))}
        </div>
      )}
    </>
  );
};

export default FormularioActividades;
