import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, Collapse } from 'reactstrap';

const ActivityCard = ({ actividad }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <Card className="mb-3">
      <div
        className="border-bottom rounded-top mt-2 px-2 py-2 pe-auto"
        onClick={toggle}
        style={{ backgroundColor: '#eef5f9', cursor: 'pointer' }}
      >
        <h4>
          {actividad.Modulo} - {actividad.Descripcion}
        </h4>
      </div>

      <Collapse isOpen={isOpen}>
        <CardBody>
          <p>
            <strong>Ciclo:</strong> {actividad.Ciclo}
          </p>
          <p>
            <strong>Actividad:</strong> {actividad.Actividad}
          </p>
          <p>
            <strong>Tutor:</strong> {actividad.Tutor === null ? '' : actividad.Tutor}
          </p>
          <p>
            <strong>Horas:</strong> {actividad.Horas}
          </p>
          <p>
            <strong>P.cordinador:</strong> {actividad.Pcordinador}
            <strong> P.adjunto 1:</strong> {actividad.Padjunto1}
            <strong> P.adjunto 2:</strong> {actividad.Padjunto2}
            <strong> P.adjunto 3:</strong> {actividad.Padjunto3}
          </p>
          <p>
            <strong>Activos:</strong> {actividad.Activos}
            <strong> Convalidada:</strong> {actividad.Convalidada}
            <strong> Importada:</strong> {actividad.Importada}
            <strong> Baja:</strong> {actividad.Baja}
            <strong> Total:</strong> {actividad.Total}
          </p>
        </CardBody>
      </Collapse>
    </Card>
  );
};

ActivityCard.propTypes = {
  actividad: PropTypes.any,
};

export default ActivityCard;
