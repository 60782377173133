import HttpService from './httpService';

class EnvioMailApiService extends HttpService {
  constructor(baseUrl, generalCatch) {
    super(baseUrl, generalCatch);
  }

  async consultaGrupos({ headquarter }) {
    return this.get('consultaGrupo', { params: { headquarter } });
  }

  async consultaAlumnosFiltrados({ headquarter, listadoGrupos, listadoCentros }) {
    return this.get('consultaAlumnosFiltrados', {
      params: { headquarter, listadoGrupos, listadoCentros },
    });
  }

  async enviarCorreoMasivo(data, { empresaId }) {
    return this.post('enviarCorreoMasivo', data, { params: { empresaId } });
  }
}

const envioMailService = new EnvioMailApiService(process.env.REACT_APP_NODE_API);

export default envioMailService;
