import { useState, useEffect, useContext } from 'react';
import { Col, Button, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import Loader from '../../../layouts/loader/Loader';
import Desplegable from './Desplegable';
import { AuthContext } from '../../../context/authContext';
import matriculacionApiService from '../../../services/http/matriculacionApiService';

const ActividadesGrupos = (props) => {
  // Preparamos variables necesarias

  const [isAllCheked, setIsAllCheked] = useState(false);
  const enrollmentSession = JSON.parse(localStorage.getItem('enrollment'));
  const [dataPrecio, setDataPrecio] = useState([]);
  const [dataApi, setDataApi] = useState([]);
  const [total, setTotal] = useState(props.sumatorio);
  const localEnrollment = JSON.parse(localStorage.getItem('enrollment'));
  const { userOU } = useContext(AuthContext);

  // Efectos
  useEffect(() => {
    const fetchData = async () => {
      try {
        const dataObtenerTotal = await matriculacionApiService.obtenerTotal({
          headquarter: localEnrollment.headquarter,
          id: enrollmentSession.id,
        });
        props.setHoursCounter(dataObtenerTotal[0].horas);
        setTotal(dataObtenerTotal[1].importe);
      } catch (error) {
        props.setHoursCounter(0);
        setTotal(props.sumatorio);
      }

      try {
        const dataDetalleMatricula = await matriculacionApiService.detalleMatricula({
          headquarter: enrollmentSession.headquarter,
          id: enrollmentSession.id,
        });
        setDataApi(dataDetalleMatricula);
      } catch (error) {
        setDataApi([]);
      }

      try {
        const dataPreciosCiclo = await matriculacionApiService.buscarPreciosCiclo({
          headquarter: localEnrollment.headquarter,
          groupId: props.id,
          id: props.ccodcli,
        });
        setDataPrecio(dataPreciosCiclo);
      } catch (error) {
        setDataPrecio([]);
      }

      try {
        const dataModulos = await matriculacionApiService.buscarModulos({
          headquarter: localEnrollment.headquarter,
          enrollmentId: props.idMatricula,
        });
        props.setActivity(dataModulos);
      } catch (error) {
        props.setActivity([]);
      }
    };

    fetchData();
  }, []);

  // Función para seleccionar todos los módulos
  const marcarTodos = () => {
    let hours = 0;
    let sumTot = 0;
    Object.entries(props.activity).forEach(([key, array]) => {
      array.forEach((obj) => {
        console.log(key);
        const value = `${obj.modulo} ${obj.descripcion}`;

        if (!props.modulos.includes(value) && obj.Nota !== 1) {
          const newArr = props.modulos;
          newArr.push(value);
          props.setModulos([...newArr]);
          sumTot += obj.precio;
          if (
            !obj.descripcion.includes('Formación en el centro de trabajo') &&
            !obj.descripcion.includes('Formación en centros de trabajo')
          ) {
            const notaAsInt = parseInt(obj.horas !== null ? obj.horas.toString() : '0', 10);
            hours += notaAsInt;
          }
        }
      });
    });

    setIsAllCheked(true);
    props.setHoursCounter(hours);
    setTotal(sumTot);
  };

  // Función para desmarcar todos los módulos
  const desmarcarTodos = () => {
    const newArr = [];
    props.setModulos(newArr);

    setIsAllCheked(false);
    props.setHoursCounter(0);
    setTotal(0);
  };

  return (
    <>
      <Row>
        <Col sm="7">
          <h4>Actividades</h4>
        </Col>
      </Row>
      {Object.keys(props.activity).length === 0 && dataPrecio.length === 0 ? (
        <Loader />
      ) : (
        <>
          <Row>
            {userOU.centro === 'ORIENTACIÓN' ? (
              ''
            ) : (
              <Col md="10">
                {isAllCheked ? (
                  <Button className="me-1 mb-2" onClick={desmarcarTodos} disabled={props.estado}>
                    <i className="bi bi-check-square" style={{ marginRight: '8px' }}></i>
                    Desmarcar todos
                  </Button>
                ) : (
                  <Button className="me-1 mb-2" onClick={marcarTodos} disabled={props.estado}>
                    <i className="bi bi-check-square-fill" style={{ marginRight: '8px' }}></i>
                    Marcar todos
                  </Button>
                )}
              </Col>
            )}
          </Row>

          <div style={{ marginTop: '8px' }}>
            {Object.entries(props.activity).map(([key, array]) => (
              <Desplegable
                key={key}
                array={array}
                activity={props.activity}
                keyArray={key}
                modulos={props.modulos}
                estado={props.estado}
                setModulos={props.setModulos}
                setHoursCounter={props.setHoursCounter}
                setTotal={setTotal}
                total={total}
                hoursCounter={props.hoursCounter}
                idMatricula={props.idMatricula}
                dataApi={dataApi}
              />
            ))}
          </div>
        </>
      )}
      <Row>
        <Col>
          <b>Horas {props.hoursCounter} </b>
          <b>Precio total {total} € </b>
        </Col>
      </Row>
      <Row>
        {props.isNoPresencial && props.hoursCounter > 2000 ? (
          <small className="text-danger">
            Ha superado el máximo de horas disponibles. (2000h sin contar FCT)
          </small>
        ) : (
          ''
        )}
      </Row>
    </>
  );
};

ActividadesGrupos.propTypes = {
  isNoPresencial: PropTypes.any,
  setModulos: PropTypes.any,
  id: PropTypes.any,
  modulos: PropTypes.any,
  sumatorio: PropTypes.any,
  idMatricula: PropTypes.any,
  estado: PropTypes.any,
  activity: PropTypes.any,
  setActivity: PropTypes.any,
  hoursCounter: PropTypes.any,
  setHoursCounter: PropTypes.any,
  ccodcli: PropTypes.any,
};

export default ActividadesGrupos;
