import HttpService from './httpService';

class DocumentosApiService extends HttpService {
  constructor(baseUrl, generalCatch) {
    super(baseUrl, generalCatch);
  }

  async exportarReciboPDF({ headquarter, reciboId }, { headquarter: headquarterParam }) {
    return this.post(
      'exportarReciboPDF',
      { headquarter, reciboId },
      { params: { headquarter: headquarterParam }, responseType: 'blob' },
    );
  }

  async generarJustificante(data) {
    return this.post('generarJustificante', data, { responseType: 'blob' });
  }

  async generarCercom(data) {
    return this.post('generarCercom', data, { responseType: 'blob' });
  }

  async generarBolnot(data) {
    return this.post('generarBolnot', data, { responseType: 'blob' });
  }

  async generarCertificadoAlimentos(data) {
    return this.post('generarCertificadoAlimentos', data, { responseType: 'blob' });
  }

  async generarCertificadoMatricula(data) {
    return this.post('generarCertificadoMatricula', data, { responseType: 'blob' });
  }

  async generarCertificadoUni(data) {
    return this.post('generarCertificadoUni', data, { responseType: 'blob' });
  }

  async generarPendienteJunta(data) {
    return this.post('generarPendienteJunta', data, { responseType: 'blob' });
  }

  async obtenerContabilidad({ empresaId, id }) {
    return this.get('obtenerContabilidad', { params: { empresaId, id } });
  }

  async generarDocAutomat(data) {
    return this.post('generarDocAutomat', data, { responseType: 'blob' });
  }
}

const documentosApiService = new DocumentosApiService(process.env.REACT_APP_NODE_API);
export default documentosApiService;
