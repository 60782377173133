import { useEffect } from 'react';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import { ModalBody, ModalFooter, Modal, Button, Label, Form, Row, Col, Input } from 'reactstrap';
import matriculacionApiService from '../../services/http/matriculacionApiService';

const ModalConvocatorias = ({
  state,
  setState,
  convoStateList,
  idActividad,
  idGrupo,
  numMatricula,
}) => {
  const validate = (values) => {
    // Preparamos variable de errores-
    const errors = {};

    // Validamos la descripción
    if (values.description === '') {
      errors.description = 'Obligatorio';
    }

    // Validamos la nota--
    if (values.nota === '') {
      errors.nota = 'Obligatorio';
    }

    if ((values.nota > 10 || values.nota < 0) && values.nota !== -3 && values.nota !== -4) {
      errors.nota = 'Nota entre 0 y 10';
    }

    // Validamos el estado
    if (values.estado === '-1') {
      errors.estado = 'Obligatorio';
    }

    if (values.estado === '4' || values.estado === 4) {
      values.nota = -4;
    } else if (values.estado === '5' || values.estado === 5) {
      values.nota = -3;
    }

    // Devolvemos los errores
    return errors;
  };

  // Variable de Formik
  const formik = useFormik({
    initialValues: {
      description: '',
      nota: '',
      estado: '-1',
    },
    validateOnChange: false,
    validate,
    onSubmit: async (values) => {
      // Preparamos variables
      const localEnrollment = JSON.parse(localStorage.getItem('enrollment'));

      try {
        await matriculacionApiService.crearConvocatoria(
          {
            idActividad,
            idGrupo,
            description: values.description,
            nota: values.nota,
            idEstado: values.estado,
            numMatricula,
          },
          {
            headquarter: localEnrollment.headquarter,
          },
        );

        setState(false);
        window.location.reload();
      } catch (error) {
        setState(true);
      }
    },
  });

  // Efecto para activar la validación al cargar el componente
  useEffect(() => {
    formik.validateForm();
  }, [formik.values]);

  return (
    <Modal isOpen={state} centered>
      <ModalBody>
        <Label className="mb-3" htmlFor="dateCreate">
          <b>Añadir nueva convocatoria</b>
        </Label>
        <Form>
          <Row className="mb-3">
            <Col sm="12">
              <Label htmlFor="description" className="form-label">
                <small>
                  Descripción <span className="text-danger">*</span>
                </small>
              </Label>
              <Input
                type="text"
                name="description"
                id="description"
                placeholder="Descripción de la convocatoria"
                onChange={formik.handleChange}
                value={formik.values.description}
                className={formik.errors.description ? 'border border-danger error rounded ' : ''}
              />
              {formik.errors.description ? (
                <div className="text-danger tiny">{formik.errors.description}</div>
              ) : null}
            </Col>
          </Row>
          <Row className="mb-3">
            <Col sm="5">
              <Label htmlFor="nota" className="form-label">
                <small>
                  Nota <span className="text-danger">*</span>
                </small>
              </Label>
              <Input
                type="number"
                name="nota"
                id="nota"
                placeholder="Nota de la convocatoria"
                onChange={formik.handleChange}
                value={formik.values.nota}
                className={formik.errors.nota ? 'border border-danger error rounded ' : ''}
              />
              {formik.errors.nota ? (
                <div className="text-danger tiny">{formik.errors.nota}</div>
              ) : null}
            </Col>
            <Col sm="7">
              <Label htmlFor="estado" className="form-label">
                <small>
                  Estado <span className="text-danger">*</span>
                </small>
              </Label>
              <Input
                type="select"
                name="estado"
                id="estado"
                onChange={formik.handleChange}
                value={formik.values.estado}
                className={formik.errors.estado ? 'border border-danger error rounded ' : ''}
              >
                {convoStateList.map((convo) => (
                  <option key={convo.id} value={convo.id}>
                    {convo.descripcion}
                  </option>
                ))}
              </Input>
              {formik.errors.estado ? (
                <div className="text-danger tiny">{formik.errors.estado}</div>
              ) : null}
            </Col>
          </Row>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button onClick={formik.handleSubmit}>Aceptar</Button>
        <Button
          color="danger"
          onClick={() => {
            setState(false);
          }}
        >
          Cancelar
        </Button>
      </ModalFooter>
    </Modal>
  );
};
ModalConvocatorias.propTypes = {
  state: PropTypes.any,
  setState: PropTypes.any,
  convoStateList: PropTypes.any,
  idActividad: PropTypes.any,
  idGrupo: PropTypes.any,
  numMatricula: PropTypes.any,
};
export default ModalConvocatorias;
