import { useContext, useEffect, useState } from 'react';
import { TabPane, Row, Col, Input, Form, Label, Button, Tooltip } from 'reactstrap';
import { useFormik } from 'formik';
import moment from 'moment';
import Datetime from 'react-datetime';
import PropTypes from 'prop-types';
import * as Icon from 'react-feather';
import 'moment/locale/es';
import { validarCIF, validarDNI, validarNIE } from '../../functions/documentType';
import ModalClickable from '../../layouts/modals/ModalClickable';
import ModalOptions from '../../layouts/modals/ModalOptions';
import 'react-datetime/css/react-datetime.css';
import esAlumnoMenor from '../../helpers/alumnoMenor';
import { AuthContext } from '../../context/authContext';
import alumnosApiService from '../../services/http/alumnosApiService';
import { httpServiceInstanceNode } from '../../services/http/httpService';
import matriculacionApiService from '../../services/http/matriculacionApiService';

const disableFutureDates = (current) => {
  // Obtiene la fecha actual
  const today = new Date();

  // Compara la fecha actual con la fecha seleccionada
  return current.isBefore(today);
};

const DatosPersonales = ({
  prevActiveTab,
  datosPersonales,
  missingDataFunction,
  menorEdad,
  setMenorEdad,
}) => {
  // Declaramos variables
  const lastUpdate = new Date();
  const { empresaId } = JSON.parse(localStorage.getItem('student'));
  const { userOU } = useContext(AuthContext);
  const { idCedesca, idCntec, centro } = userOU;

  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggleTooltip = () => setTooltipOpen(!tooltipOpen);
  // Declaramos estados
  const [isModalShown, setIsModalShown] = useState(false);
  const [isModalShown2, setIsModalShown2] = useState(false);
  const [isModalShown3, setIsModalShown3] = useState(false);
  const [document, setDocument] = useState('');
  const [country, setCountry] = useState('');
  const [province, setProvince] = useState('');
  const [poblation, setPoblation] = useState('');
  const [formationCenter, setFormationCenter] = useState('');
  const [poblationFiltered, setPoblationFiltered] = useState('');
  const [poblationBirthFiltered, setPoblationBirthFiltered] = useState('');
  const [cpFiltered, setCPFiltered] = useState('');
  const [cp, setCP] = useState('');
  const [raw, setRaw] = useState('');
  const [url, setUrl] = useState('');
  const [fidelitationList, setFidelitationList] = useState([]);

  // Declaramos la función de validación
  const validate = (values) => {
    // Preparamos variable de errores
    const errors = {};
    // Validamos el nombre
    if (!values.nombre) {
      errors.nombre = 'Obligatorio';
      missingDataFunction(true);
    } else if (!/^(?![\s.]+$)[a-zA-ZÀ-ÿ\s.]*$/i.test(values.nombre.trim())) {
      errors.nombre = 'Nombre incorrecto';
      missingDataFunction(true);
    }

    // Validamos apellidos
    if (!values.apellidos) {
      errors.apellidos = 'Obligatorio';
      missingDataFunction(true);
    } else if (!/^(?![\s.]+$)[a-zA-ZÀ-ÿ\s.]*$/i.test(values.apellidos.trim())) {
      errors.apellidos = 'Apellidos incorrectos';
      missingDataFunction(true);
    }

    // Validamos el tipo de documento
    if (values.tipoDocumento === '-1') {
      errors.tipoDocumento = 'Obligatorio';
      missingDataFunction(true);
    }

    // Validamos el documento
    if (!values.documento) {
      errors.documento = 'Obligatorio';
      missingDataFunction(true);
    } else if (values.tipoDocumento === '0' && !validarDNI(values.documento)) {
      errors.documento = 'Formato incorrecto';
      missingDataFunction(true);
    } else if (values.tipoDocumento === '1' && !validarNIE(values.documento)) {
      errors.documento = 'Formato incorrecto';
      missingDataFunction(true);
    } else if (values.tipoDocumento === '3' && !validarCIF(values.documento)) {
      errors.documento = 'Formato incorrecto';
      missingDataFunction(true);
    }

    // Validamos la fecha de nacimiento
    if (!values.fechaNacimiento) {
      errors.fechaNacimiento = 'Obligatorio';
      missingDataFunction(true);
    }

    // Validamos el país de residencia
    if (!values.pais || values.pais === ' ') {
      errors.pais = 'Obligatorio';
      missingDataFunction(true);
    }

    // Validamos la provincia de residencia
    if ((!values.provincia || values.provincia === ' ') && values.pais === 'España') {
      errors.provincia = 'Obligatorio';
      missingDataFunction(true);
    }

    // Validamos el municipio de residencia
    if (!values.municipio && values.pais === 'España') {
      errors.municipio = 'Obligatorio';
      missingDataFunction(true);
    }

    // Validamos el código postal
    if (!values.cp && values.pais === 'España') {
      errors.cp = 'Obligatorio';
      missingDataFunction(true);
    }

    // Validamos el domicilio
    if (!values.domicilio) {
      errors.domicilio = 'Obligatorio';
      missingDataFunction(true);
    }

    // Validamos el país de nacimiento
    if (!values.paisNacimiento || values.paisNacimiento === ' ') {
      errors.paisNacimiento = 'Obligatorio';
      missingDataFunction(true);
    }

    // Validamos la provincia de nacimiento
    if (
      (!values.provinciaNacimiento || values.provinciaNacimiento === ' ') &&
      values.paisNacimiento === 'España'
    ) {
      errors.provinciaNacimiento = 'Obligatorio';
      missingDataFunction(true);
    }

    // Validamos el municipio de nacimiento
    if (values.paisNacimiento === 'España' && !values.municipioNacimiento) {
      errors.municipioNacimiento = 'Obligatorio';
      missingDataFunction(true);
    }

    // Validamos la nacionalidad
    if (!values.nacionalidad) {
      errors.nacionalidad = 'Obligatorio';
      missingDataFunction(true);
    }

    // Validamos el número de la seguridad social (no obligatorio)
    if (values.nss.toString().length > 0 && values.nss.toString().length !== 12) {
      errors.nss = 'Longitud incorrecta';
      missingDataFunction(true);
    }

    // Validamos el centro
    if (values.centro === '') {
      values.centro = formationCenter[0].Nombre;
    }
    if (values.centro === '-1') {
      errors.centro = 'Obligatorio';
      missingDataFunction(true);
    }

    // Validamos el sexo
    if (values.sexo === '0' || values.sexo === 0) {
      errors.sexo = 'Obligatorio';
      missingDataFunction(true);
    }

    // Validamos el email personal
    if (!values.emailPers) {
      errors.emailPers = 'Obligatorio';
      missingDataFunction(true);
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.emailPers)) {
      errors.emailPers = 'Email incorrecto';
      missingDataFunction(true);
    }

    if (values.telefono.length > 0 && !/^\d{9}$/i.test(values.telefono)) {
      errors.telefono = 'Teléfono incorrecto';
      missingDataFunction(true);
    }

    // Validamos el móvil
    if (!values.movil) {
      errors.movil = 'Obligatorio';
      missingDataFunction(true);
    } else if (!/^\d{9}$/i.test(values.movil)) {
      errors.movil = 'Móvil incorrecto';
      missingDataFunction(true);
    }

    // Devolvemos los errores
    return errors;
  };

  const saveData = async (values, whitModal = true) => {
    // Preparamos variables
    const empresa = JSON.parse(localStorage.getItem('student')).empresaId;
    let isUpdated = 0;

    try {
      // Llamamos a la API para comprobar si se ha actualizado el usuario
      const data = await alumnosApiService.comprobarActualizacion({
        empresaId: empresa,
        alumno: datosPersonales.id,
        date: lastUpdate,
      });
      isUpdated = data;

      if (isUpdated === 0) {
        setIsModalShown(true);
      } else {
        const urlToGo = `${process.env.REACT_APP_NODE_API}actualizarUsuarioPersonales?empresaId=${
          JSON.parse(localStorage.getItem('student')).empresaId
        }`;

        // TODO: Configurar raw
        const rawData = {
          id: values.id,
          nombre: values.nombre,
          apellidos: values.apellidos,
          tipoDocumento: values.tipoDocumento,
          documento: values.documento,
          fechaNacimiento: values.fechaNacimiento,
          pais: values.pais,
          provincia: values.provincia,
          municipio: values.municipio,
          cp: values.cp,
          domicilio: values.domicilio,
          paisNacimiento: values.paisNacimiento,
          provinciaNacimiento: values.provinciaNacimiento,
          municipioNacimiento: values.municipioNacimiento,
          nacionalidad: values.nacionalidad,
          nss: values.nss,
          centro: values.centro,
          sexo: values.sexo,
          emailPers: values.emailPers,
          telefono: values.telefono,
          movil: values.movil,
          conocer: values.conocer,
          dndconocer: values.dndconocer,
          headquarter: JSON.parse(localStorage.getItem('student')).empresaId,
          idD2L: values.idD2L,
          lopdSede: values.lopdSede ? 'Consiento' : 'No Consiento',
          lopdComunicaciones: values.lopdComunicaciones ? 'Consiento' : 'No Consiento',
        };
        console.log(rawData, 'hey');
        if (whitModal) {
          setRaw(rawData);
          setUrl(urlToGo);
          setIsModalShown2(true);
        } else {
          await httpServiceInstanceNode.post(urlToGo, rawData);
        }
      }
    } catch (error) {
      // No hacer nada específico aquí.
    }
  };

  // Variable de Formik
  const formik = useFormik({
    initialValues: {
      ...datosPersonales,
      cp: datosPersonales.cp === null ? '' : datosPersonales.cp,
      tipoDocumento: datosPersonales.tipoDocumento.toString(),
      documento: datosPersonales.documento === null ? '' : datosPersonales.documento,
      domicilio: datosPersonales.domicilio === null ? '' : datosPersonales.domicilio,
      municipio: datosPersonales.municipio === null ? '' : datosPersonales.municipio,
      municipioNacimiento:
        datosPersonales.municipioNacimiento === null ? '' : datosPersonales.municipioNacimiento,
      nacionalidad: datosPersonales.nacionalidad === null ? '' : datosPersonales.nacionalidad,
      nss: datosPersonales.nss === null ? '' : datosPersonales.nss,
      pais: datosPersonales.pais === null ? 'España' : datosPersonales.pais,
      paisNacimiento:
        datosPersonales.paisNacimiento === null ? 'España' : datosPersonales.paisNacimiento,
      provinciaNacimiento:
        datosPersonales.provinciaNacimiento === null ? ' ' : datosPersonales.provinciaNacimiento,
      telefono: datosPersonales.telefono === null ? '' : datosPersonales.telefono,
      fechaNacimiento:
        datosPersonales.fechaNacimiento !== '' ? moment(datosPersonales.fechaNacimiento) : '',
      lopdSede: datosPersonales.lopdSede === 'Consiento',
      lopdComunicaciones: datosPersonales.lopdComunicaciones === 'Consiento',
      lopdFecha: datosPersonales.lopdFecha !== '' ? moment(datosPersonales.lopdFecha) : '',
      idD2L: datosPersonales.idD2L,
    },
    validateOnChange: false,
    validate,
    onSubmit: (values) => saveData(values, true),
  });

  // Declaramos efectos
  useEffect(() => {
    const fetchOptions = async () => {
      let pobl = datosPersonales.municipio === null ? '' : datosPersonales.municipio;

      pobl = pobl.replace(/[áä]/g, 'a');
      pobl = pobl.replace(/[éë]/g, 'e');
      pobl = pobl.replace(/[íï]/g, 'i');
      pobl = pobl.replace(/[óö]/g, 'o');
      pobl = pobl.replace(/[úü]/g, 'u');

      // Reemplazar acentos abiertos
      pobl = pobl.replace(/[à]/g, 'a');
      pobl = pobl.replace(/[è]/g, 'e');
      pobl = pobl.replace(/[ì]/g, 'i');
      pobl = pobl.replace(/[ò]/g, 'o');
      pobl = pobl.replace(/[ù]/g, 'u');

      // Reemplazar acentos cerrados
      pobl = pobl.replace(/[á]/g, 'a');
      pobl = pobl.replace(/[é]/g, 'e');
      pobl = pobl.replace(/[í]/g, 'i');
      pobl = pobl.replace(/[ó]/g, 'o');
      pobl = pobl.replace(/[ú]/g, 'u');

      // Peticiones API
      try {
        const paises = await alumnosApiService.cargarPaises({ empresaId: '1' });
        setCountry(paises);
      } catch (error) {
        setCountry('');
      }

      try {
        const provincias = await alumnosApiService.cargarProvincia({ empresaId: '1' });
        setProvince(provincias);
      } catch (error) {
        setProvince('');
      }

      try {
        const poblaciones = await alumnosApiService.cargarPoblaciones({
          empresaId,
          municipio: pobl,
        });
        setPoblation(poblaciones);
      } catch (error) {
        setPoblation('');
      }

      try {
        const fidelizacionList = await matriculacionApiService.cargarFidelizacion({
          empresaId,
        });
        setFidelitationList(fidelizacionList);
      } catch (error) {
        setFidelitationList([]);
      }

      try {
        const cps = await alumnosApiService.cargarCp({ empresaId, municipio: pobl });
        setCP(cps);
      } catch (error) {
        setCP('');
      }

      try {
        const centros = await alumnosApiService.cargarCentrosFormacion({ empresaId });
        setFormationCenter(centros);
      } catch (error) {
        setFormationCenter('');
      }

      try {
        const documentos = await alumnosApiService.cargarDocumentos({ empresaId });
        setDocument(documentos);
      } catch (error) {
        setDocument('');
      }
    };

    fetchOptions();
  }, []);

  // Efecto para controlar los campos de dirección
  useEffect(() => {
    if (formik.values.pais === ' ' || formik.values.pais !== 'España') {
      formik.setValues((prev) => ({ ...prev, provincia: ' ', municipio: '', cp: '' }));
    }
  }, [formik.values.pais]);

  useEffect(() => {
    if (formik.values.paisNacimiento === ' ' || formik.values.paisNacimiento !== 'España') {
      formik.setValues((prev) => ({ ...prev, provinciaNacimiento: ' ', municipioNacimiento: '' }));
    }
  }, [formik.values.paisNacimiento]);

  // Efecto para el campo de documento
  useEffect(() => {
    if (formik.values.tipoDocumento === '-1') {
      formik.setValues((prev) => ({ ...prev, documento: '' }));
    }
  }, [formik.values.tipoDocumento]);

  // Efecto para setear centro por defecto
  useEffect(() => {
    // Si el rol es de delegación, asignamos valor por defecto
    if (formationCenter.length > 0 && idCedesca > 0 && idCntec > 0) {
      const idCentroFiltrado = formationCenter.filter((center) => center.Nombre === centro)[0].Id;
      formik.setValues((prev) => ({ ...prev, centro: idCentroFiltrado }));
    }
  }, [formationCenter]);

  // Efecto para activar la validación al cargar el componente
  useEffect(() => {
    formik.validateForm();
  }, [formik.values]);

  // Efecto para guardar al cambiar de pestaña y resetear el dirty de formik.
  useEffect(() => {
    const exec = async () => {
      try {
        if (prevActiveTab === '1') {
          if (formik.dirty) await saveData(formik.values, false);
        } else {
          formik.resetForm({ values: formik.values });
        }
      } catch (error) {
        // No hacer nada específico aquí.
      }
    };

    exec();
  }, [prevActiveTab]);

  // Efecto para actualizar el select de los municipios
  useEffect(() => {
    const selectedProvince = formik.values.provincia;
    const selectedBirthProvince = formik.values.provinciaNacimiento;
    let arrayProvince = [];
    let arrayBirthProvince = [];

    if (selectedProvince && poblation.length > 0) {
      arrayProvince = poblation.filter((objeto) => objeto.provincia === selectedProvince);
      const nuevoObjeto = { nom_municipi: '', provincia: selectedProvince };
      arrayProvince.unshift(nuevoObjeto);
    }

    if (selectedBirthProvince && poblation.length > 0) {
      arrayBirthProvince = poblation.filter((objeto) => objeto.provincia === selectedBirthProvince);
      const nuevoObjeto = { nom_municipi: '', provincia: selectedBirthProvince };
      arrayBirthProvince.unshift(nuevoObjeto);
    }

    setPoblationFiltered(arrayProvince);
    setPoblationBirthFiltered(arrayBirthProvince);
  }, [poblation]);

  // Efecto para actualizar el select del código postal
  useEffect(() => {
    const selectedMunicipality = formik.values.municipio;

    if (selectedMunicipality && cp.length > 0) {
      const arrayFiltrado = cp.filter((objeto) => objeto.nom_municipi === selectedMunicipality);
      const nuevoObjeto = { cp: '', nom_municipi: selectedMunicipality };
      arrayFiltrado.unshift(nuevoObjeto);

      setCPFiltered(arrayFiltrado);
    }
  }, [cp]);

  // Efecto to para calcular la edad del usuario
  useEffect(() => {
    setMenorEdad(esAlumnoMenor(formik.values.fechaNacimiento));
  }, [formik.values.fechaNacimiento]);

  const handleFechaNacimiento = (value) => {
    formik.setValues({
      ...formik.values,
      fechaNacimiento: value,
    });

    // Calculamos si el alumno es menor
    setMenorEdad(esAlumnoMenor(value));
  };

  const handleProvinceChange = (event) => {
    const selectedProvince = event.target.value;

    const arrayFiltrado = poblation.filter((objeto) => objeto.provincia === selectedProvince);
    const nuevoObjeto = { nom_municipi: '', provincia: selectedProvince };
    arrayFiltrado.unshift(nuevoObjeto);

    // Actualiza los valores en el estado de Formik
    formik.setValues({
      ...formik.values,
      provincia: selectedProvince,
      municipio: arrayFiltrado[0].nom_municipi,
      cp: '',
    });

    setPoblationFiltered(arrayFiltrado);
  };

  const handlePoblationChange = (event) => {
    const selectedMunicipality = event.target.value;

    const arrayFiltrado = cp.filter((objeto) => objeto.nom_municipi === selectedMunicipality);
    const nuevoObjeto = { cp: '', nom_municipi: selectedMunicipality };
    arrayFiltrado.unshift(nuevoObjeto);

    // Actualiza los valores en el estado de Formik
    formik.setValues({
      ...formik.values,
      municipio: selectedMunicipality,
      cp: arrayFiltrado[0].cp,
    });

    setCPFiltered(arrayFiltrado);
  };

  const handleBirthProvinceChange = (event) => {
    const selectedProvince = event.target.value;

    const arrayFiltrado = poblation.filter((objeto) => objeto.provincia === selectedProvince);
    const nuevoObjeto = { nom_municipi: '', provincia: selectedProvince };
    arrayFiltrado.unshift(nuevoObjeto);

    // Actualiza los valores en el estado de Formik
    formik.setValues({
      ...formik.values,
      provinciaNacimiento: selectedProvince,
      municipioNacimiento: arrayFiltrado[0].nom_municipi,
    });

    setPoblationBirthFiltered(arrayFiltrado);
  };

  const handleEmail = () => {
    const urlToGo = `${process.env.REACT_APP_NODE_API}enviarMailCredenciales?empresaId=${
      JSON.parse(localStorage.getItem('student')).empresaId
    }`;
    // TODO: Configurar raw
    const rawData = {
      id: formik.values.id,
      emailO: formik.values.emailPers,
    };

    setRaw(rawData);
    setUrl(urlToGo);
    setIsModalShown3(true);
  };

  return (
    <>
      {isModalShown ? (
        <ModalClickable header="Un usuario ha modificado este alumno recientemente." />
      ) : (
        ''
      )}
      {isModalShown2 ? (
        <ModalOptions
          header="¿Desea realizar estos cambios?"
          url={url}
          raw={raw}
          load={setIsModalShown2}
          text1="Cancelar"
          color1="danger"
          text2="Aceptar"
          color2="primary"
        />
      ) : (
        ''
      )}
      {isModalShown3 ? (
        <ModalOptions
          header="¿Desea reenviar el email con las credenciales?"
          url={url}
          raw={raw}
          load={setIsModalShown3}
          text1="Cancelar"
          color1="danger"
          text2="Enviar"
          color2="primary"
        />
      ) : (
        ''
      )}

      <TabPane tabId="1">
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            formik.handleSubmit();
          }}
        >
          <Row>
            <Col md="1">
              <Label htmlFor="id">
                <small>ID</small>
              </Label>
              <Input id="id" name="id" type="text" value={formik.values.id} disabled />
            </Col>
            <Col md="2">
              <Label htmlFor="nombre">
                <small>
                  Nombre <span className="text-danger">*</span>
                </small>
              </Label>
              <Input
                id="nombre"
                name="nombre"
                type="text"
                disabled={userOU.centro === 'ORIENTACIÓN'}
                onChange={formik.handleChange}
                value={formik.values.nombre}
                className={formik.errors.nombre ? 'border border-danger error' : ''}
              />
              {formik.errors.nombre ? (
                <div className="text-danger tiny">{formik.errors.nombre}</div>
              ) : null}
            </Col>
            <Col md="3">
              <Label htmlFor="apellidos">
                <small>
                  Apellidos <span className="text-danger">*</span>
                </small>
              </Label>
              <Input
                id="apellidos"
                name="apellidos"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.apellidos}
                className={formik.errors.apellidos ? 'border border-danger error' : ''}
                disabled={userOU.centro === 'ORIENTACIÓN'}
              />
              {formik.errors.apellidos ? (
                <div className="text-danger tiny">{formik.errors.apellidos}</div>
              ) : null}
            </Col>
            <Col md="2">
              <Label htmlFor="tipoDocumento">
                <small>
                  Tipo de documento <span className="text-danger">*</span>
                </small>
              </Label>
              <Input
                id="tipoDocumento"
                name="tipoDocumento"
                type="select"
                disabled={userOU.centro === 'ORIENTACIÓN'}
                onChange={formik.handleChange}
                value={formik.values.tipoDocumento}
                className={formik.errors.tipoDocumento ? 'border border-danger error' : ''}
              >
                {document !== ''
                  ? document.map((option) => (
                      <option key={`${option.id}-documento`} value={option.id}>
                        {option.nombre}
                      </option>
                    ))
                  : ''}
              </Input>
              {formik.errors.tipoDocumento ? (
                <div className="text-danger tiny">{formik.errors.tipoDocumento}</div>
              ) : null}
            </Col>
            <Col md="2">
              <Label htmlFor="documento">
                <small>
                  Documento <span className="text-danger">*</span>
                </small>
              </Label>
              <Input
                id="documento"
                name="documento"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.documento}
                className={formik.errors.documento ? 'border border-danger error' : ''}
                disabled={formik.values.tipoDocumento === '-1' || userOU.centro === 'ORIENTACIÓN'}
              />
              {formik.errors.documento ? (
                <div className="text-danger tiny">{formik.errors.documento}</div>
              ) : null}
            </Col>
            <Col md="2">
              <Label htmlFor="fechaNacimiento">
                <small>
                  Fecha nacimiento <span className="text-danger">*</span>
                </small>
              </Label>
              <Datetime
                utc
                timeFormat={false}
                isValidDate={disableFutureDates}
                onChange={handleFechaNacimiento}
                value={formik.values.fechaNacimiento}
                locale="es"
                className={
                  formik.errors.fechaNacimiento ? 'border border-danger error rounded ' : ''
                }
                inputProps={userOU.centro === 'ORIENTACIÓN' ? { disabled: true } : ''}
              />
              {menorEdad && (
                <span className="text-warning">
                  <b>Es alumno menor</b>
                </span>
              )}
              {formik.errors.fechaNacimiento ? (
                <div className="text-danger tiny">{formik.errors.fechaNacimiento}</div>
              ) : null}
            </Col>
          </Row>
          <Row className="mt-2">
            <Col md="2">
              <Label htmlFor="pais">
                <small>
                  País de residencia <span className="text-danger">*</span>
                </small>
              </Label>
              <Input
                id="pais"
                name="pais"
                type="select"
                onChange={formik.handleChange}
                value={formik.values.pais}
                className={formik.errors.pais ? 'border border-danger error' : ''}
                disabled={userOU.centro === 'ORIENTACIÓN'}
              >
                {country !== ''
                  ? country.map((option) => (
                      <option key={option.idcodi_pais} value={option.nom}>
                        {option.nom}
                      </option>
                    ))
                  : ''}
              </Input>
              {formik.errors.pais ? (
                <div className="text-danger tiny">{formik.errors.pais}</div>
              ) : null}
            </Col>
            <Col md="2">
              <Label htmlFor="provincia">
                <small>
                  Provincia de residencia{' '}
                  {formik.values.pais !== 'España' ? '' : <span className="text-danger">*</span>}
                </small>
              </Label>
              <Input
                id="provincia"
                name="provincia"
                type="select"
                disabled={formik.values.pais !== 'España' || userOU.centro === 'ORIENTACIÓN'}
                onChange={handleProvinceChange}
                value={formik.values.provincia}
                className={formik.errors.provincia ? 'border border-danger error' : ''}
              >
                {province !== ''
                  ? province.map((option) => (
                      <option key={option.provincia} value={option.provincia}>
                        {option.provincia}
                      </option>
                    ))
                  : ''}
              </Input>
              {formik.errors.provincia ? (
                <div className="text-danger tiny">{formik.errors.provincia}</div>
              ) : null}
            </Col>
            <Col md="3">
              <Label htmlFor="municipio">
                <small>
                  Municipio de residencia{' '}
                  {formik.values.pais !== 'España' ? '' : <span className="text-danger">*</span>}
                </small>
              </Label>
              <Input
                id="municipio"
                name="municipio"
                type="select"
                disabled={formik.values.pais !== 'España' || userOU.centro === 'ORIENTACIÓN'}
                onChange={handlePoblationChange}
                value={formik.values.municipio}
                className={formik.errors.municipio ? 'border border-danger error' : ''}
              >
                {poblationFiltered !== ''
                  ? poblationFiltered.map((option) => (
                      <option key={option.nom_municipi} value={option.nom_municipi}>
                        {option.nom_municipi}
                      </option>
                    ))
                  : ''}
              </Input>
              {formik.errors.municipio ? (
                <div className="text-danger tiny">{formik.errors.municipio}</div>
              ) : null}
            </Col>
            <Col md="2">
              <Label htmlFor="cp">
                <small>
                  Código postal{' '}
                  {formik.values.pais !== 'España' ? '' : <span className="text-danger">*</span>}
                </small>
              </Label>
              <Input
                id="cp"
                name="cp"
                type="select"
                disabled={formik.values.pais !== 'España' || userOU.centro === 'ORIENTACIÓN'}
                onChange={formik.handleChange}
                value={formik.values.cp}
                className={formik.errors.cp ? 'border border-danger error' : ''}
              >
                {cpFiltered !== ''
                  ? cpFiltered.map((option) => (
                      <option key={`${option.cp}${option.nom_municipi}`} value={option.cp}>
                        {option.cp}
                      </option>
                    ))
                  : ''}
              </Input>
              {formik.errors.cp ? <div className="text-danger tiny">{formik.errors.cp}</div> : null}
            </Col>
            <Col md="3">
              <Label htmlFor="domicilio">
                <small>
                  Domicilio <span className="text-danger">*</span>
                </small>
              </Label>
              <Input
                id="domicilio"
                name="domicilio"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.domicilio}
                className={formik.errors.domicilio ? 'border border-danger error' : ''}
                disabled={userOU.centro === 'ORIENTACIÓN'}
              />
              {formik.errors.domicilio ? (
                <div className="text-danger tiny">{formik.errors.domicilio}</div>
              ) : null}
            </Col>
          </Row>
          <Row className="mt-2">
            <Col md="2">
              <Label htmlFor="paisNacimiento">
                <small>
                  País de nacimiento <span className="text-danger">*</span>
                </small>
              </Label>
              <Input
                id="paisNacimiento"
                name="paisNacimiento"
                type="select"
                onChange={formik.handleChange}
                value={formik.values.paisNacimiento}
                className={formik.errors.paisNacimiento ? 'border border-danger error' : ''}
                disabled={userOU.centro === 'ORIENTACIÓN'}
              >
                {country !== ''
                  ? country.map((option) => (
                      <option key={`${option.idcodi_pais}-nac`} value={option.nom}>
                        {option.nom}
                      </option>
                    ))
                  : ''}
              </Input>
              {formik.errors.paisNacimiento ? (
                <div className="text-danger tiny">{formik.errors.paisNacimiento}</div>
              ) : null}
            </Col>
            <Col md="2">
              <Label htmlFor="provinciaNacimiento">
                <small>
                  Provincia de nacimiento{' '}
                  {formik.values.paisNacimiento !== 'España' ? (
                    ''
                  ) : (
                    <span className="text-danger">*</span>
                  )}
                </small>
              </Label>
              <Input
                id="provinciaNacimiento"
                name="provinciaNacimiento"
                type="select"
                onChange={handleBirthProvinceChange}
                value={formik.values.provinciaNacimiento}
                className={formik.errors.provinciaNacimiento ? 'border border-danger error' : ''}
                disabled={
                  formik.values.paisNacimiento !== 'España'
                    ? 'disabled'
                    : '' || userOU.centro === 'ORIENTACIÓN'
                }
              >
                {province !== ''
                  ? province.map((option) => (
                      <option key={`${option.provincia}-nac`} value={option.provincia}>
                        {option.provincia}
                      </option>
                    ))
                  : ''}
              </Input>
              {formik.errors.provinciaNacimiento ? (
                <div className="text-danger tiny">{formik.errors.provinciaNacimiento}</div>
              ) : null}
            </Col>
            <Col md="3">
              <Label htmlFor="municipioNacimiento">
                <small>
                  Municipio de nacimiento{' '}
                  {formik.values.paisNacimiento !== 'España' ? (
                    ''
                  ) : (
                    <span className="text-danger">*</span>
                  )}
                </small>
              </Label>
              <Input
                id="municipioNacimiento"
                name="municipioNacimiento"
                type="select"
                onChange={formik.handleChange}
                value={formik.values.municipioNacimiento}
                className={formik.errors.municipioNacimiento ? 'border border-danger error' : ''}
                disabled={
                  formik.values.paisNacimiento !== 'España'
                    ? 'disabled'
                    : '' || userOU.centro === 'ORIENTACIÓN'
                }
              >
                {poblationBirthFiltered !== ''
                  ? poblationBirthFiltered.map((option) => (
                      <option key={`${option.nom_municipi}-nac`} value={option.nom_municipi}>
                        {option.nom_municipi}
                      </option>
                    ))
                  : ''}
              </Input>
              {formik.errors.municipioNacimiento ? (
                <div className="text-danger tiny">{formik.errors.municipioNacimiento}</div>
              ) : null}
            </Col>
            <Col md="2">
              <Label htmlFor="nacionalidad">
                <small>
                  Nacionalidad <span className="text-danger">*</span>
                </small>
              </Label>
              <Input
                id="nacionalidad"
                name="nacionalidad"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.nacionalidad}
                className={formik.errors.nacionalidad ? 'border border-danger error' : ''}
                disabled={userOU.centro === 'ORIENTACIÓN'}
              />
              {formik.errors.nacionalidad ? (
                <div className="text-danger tiny">{formik.errors.nacionalidad}</div>
              ) : null}
            </Col>
            <Col md="3">
              <Label htmlFor="nss">
                <small>Núm. Seguridad Social</small>
              </Label>
              <Input
                id="nss"
                name="nss"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.nss}
                className={formik.errors.nss ? 'border border-danger error' : ''}
                disabled={userOU.centro === 'ORIENTACIÓN'}
              />
              {formik.errors.nss ? (
                <div className="text-danger tiny">{formik.errors.nss}</div>
              ) : null}
            </Col>
          </Row>
          <Row className="mt-2">
            <Col md="2">
              <Label htmlFor="centro">
                <small>
                  Centro <span className="text-danger">*</span>
                </small>
              </Label>
              <Input
                id="centro"
                name="centro"
                type="select"
                onChange={formik.handleChange}
                className={formik.errors.centro ? 'border border-danger error' : ''}
                disabled={(idCedesca > 0 && idCntec > 0) || userOU.centro === 'ORIENTACIÓN'}
                value={formik.values.centro}
              >
                {formationCenter !== ''
                  ? formationCenter.map((option) => (
                      <option key={`${option.Id}-centro`} value={option.Id}>
                        {option.Nombre}
                      </option>
                    ))
                  : ''}
              </Input>
              {formik.errors.centro ? (
                <div className="text-danger tiny">{formik.errors.centro}</div>
              ) : null}
            </Col>
            <Col md="2">
              <Label htmlFor="sexo">
                <small>
                  Sexo <span className="text-danger">*</span>
                </small>
              </Label>
              <Input
                id="sexo"
                name="sexo"
                type="select"
                onChange={formik.handleChange}
                value={formik.values.sexo}
                className={formik.errors.sexo ? 'border border-danger error rounded ' : ''}
                disabled={userOU.centro === 'ORIENTACIÓN'}
              >
                <option key="0-sexo" value="0"></option>
                <option key="1-sexo" value="1">
                  Hombre
                </option>
                <option key="2-sexo" value="2">
                  Mujer
                </option>
                <option key="3-sexo" value="3">
                  No binario
                </option>
              </Input>
              {formik.errors.sexo ? (
                <div className="text-danger tiny">{formik.errors.sexo}</div>
              ) : null}
            </Col>
            <Col md="3">
              <Label htmlFor="emailPers">
                <small>
                  Email <span className="text-danger">*</span>
                </small>
              </Label>
              <Input
                id="emailPers"
                name="emailPers"
                type="email"
                onChange={formik.handleChange}
                value={formik.values.emailPers}
                className={formik.errors.emailPers ? 'border border-danger error' : ''}
                disabled={userOU.centro === 'ORIENTACIÓN'}
              />
              {formik.errors.emailPers ? (
                <div className="text-danger tiny">{formik.errors.emailPers}</div>
              ) : null}
            </Col>
            <Col md="2">
              <Label htmlFor="telefono">
                <small>Teléfono</small>
              </Label>
              <Input
                id="telefono"
                name="telefono"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.telefono}
                className={formik.errors.telefono ? 'border border-danger error' : ''}
                disabled={userOU.centro === 'ORIENTACIÓN'}
              />
              {formik.errors.telefono ? (
                <div className="text-danger tiny">{formik.errors.telefono}</div>
              ) : null}
            </Col>
            <Col md="2">
              <Label htmlFor="movil">
                <small>
                  Móvil <span className="text-danger">*</span>
                </small>
              </Label>
              <Input
                id="movil"
                name="movil"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.movil}
                className={formik.errors.movil ? 'border border-danger error' : ''}
                disabled={userOU.centro === 'ORIENTACIÓN'}
              />
              {formik.errors.movil ? (
                <div className="text-danger tiny">{formik.errors.movil}</div>
              ) : null}
            </Col>
          </Row>
          <Row className="mt-2">
            <Col md="2">
              <Label htmlFor="conocer">
                <small>
                  ¿Cómo nos has conocido?<span className="text-danger">*</span>
                </small>
              </Label>
              <Input
                id="conocer"
                name="conocer"
                type="select"
                onChange={formik.handleChange}
                value={formik.values.conocer}
                className={formik.errors.conocer ? 'border border-danger error' : ''}
                disabled={userOU.centro === 'ORIENTACIÓN'}
              >
                {fidelitationList !== ''
                  ? fidelitationList.map((option) => (
                      <option key={option.id} value={option.nom}>
                        {option.nom}
                      </option>
                    ))
                  : ''}
              </Input>
              {formik.errors.municipio ? (
                <div className="text-danger tiny">{formik.errors.municipio}</div>
              ) : null}
            </Col>
            <Col md="2">
              <Label htmlFor="dndconocer">
                <small>¿Dónde nos has conocido?</small>
              </Label>
              <Input
                id="dndconocer"
                name="dndconocer"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.dndconocer}
                className={formik.errors.dndconocer ? 'border border-danger error' : ''}
                disabled={userOU.centro === 'ORIENTACIÓN'}
              />
            </Col>
            <Col md="1">
              <Label htmlFor="id">
                <small>ID Campus</small>
              </Label>
              <Input
                id="idD2L"
                name="idD2L"
                type="text"
                onChange={formik.handleChange}
                defaultValue={formik.values.idD2L}
                disabled={userOU.centro !== 'SUPERADMIN'}
              />
            </Col>
          </Row>
          <Row className="mt-2">
            <Col md="2">
              <Label htmlFor="lopdFecha">
                <small></small>
              </Label>
              <Datetime
                utc
                timeFormat={false}
                isValidDate={disableFutureDates}
                value={formik.values.lopdFecha}
                locale="es"
                inputProps={{ disabled: true }}
              />
            </Col>
            <Col md="4">
              <div style={{ display: 'flex', alignItems: 'center', gap: '8px', marginTop: '25px' }}>
                <Input
                  type="checkbox"
                  name="lopdSede"
                  defaultChecked={formik.values.lopdSede}
                  onChange={formik.handleChange}
                  style={{
                    display: 'block',
                    height: '38px',
                    width: '38px',
                    margin: '0',
                  }}
                />
                <Label htmlFor="lopdSede" style={{ margin: 0 }}>
                  <small>Autoriza el acceso a notas a delegaciones</small>
                </Label>
              </div>
            </Col>
            <Col md="4">
              <div style={{ display: 'flex', alignItems: 'center', gap: '8px', marginTop: '25px' }}>
                <Input
                  type="checkbox"
                  name="lopdComunicaciones"
                  defaultChecked={formik.values.lopdComunicaciones}
                  onChange={formik.handleChange}
                  style={{
                    display: 'block',
                    height: '38px',
                    width: '38px',
                    margin: '0',
                  }}
                />
                <Label htmlFor="lopdComunicaciones" style={{ margin: 0 }}>
                  <small>Autoriza recibir comunicaciones de márketing</small>
                </Label>
              </div>
            </Col>
          </Row>
          <Row>
            <Col sm="1" className="mt-1 mt-md-0">
              {userOU.centro === 'ORIENTACIÓN' ? (
                ''
              ) : (
                <Button type="submit" color="success" className="mt-3 w-auto">
                  <Icon.Save />
                </Button>
              )}
            </Col>
            <Col sm="11" className="mt-1 mt-md-0 d-flex justify-content-end">
              {(userOU.centro === 'SECRETARÍA' || userOU.centro === 'SUPERADMIN') &&
              formik.values.idD2L !== '' ? (
                <>
                  <Button onClick={handleEmail} className="mt-3 w-auto" id="reenviarTooltip">
                    <Icon.Mail />
                  </Button>
                  <Tooltip
                    placement="top"
                    isOpen={tooltipOpen}
                    target="reenviarTooltip"
                    toggle={toggleTooltip}
                  >
                    Reenviar credenciales
                  </Tooltip>
                </>
              ) : (
                ''
              )}
            </Col>
          </Row>
        </Form>
      </TabPane>
    </>
  );
};

DatosPersonales.propTypes = {
  prevActiveTab: PropTypes.string,
  datosPersonales: PropTypes.any,
  missingDataFunction: PropTypes.any,
  menorEdad: PropTypes.any,
  setMenorEdad: PropTypes.any,
};

export default DatosPersonales;
