import { useEffect, useState } from 'react';
import { Button } from 'reactstrap';
import ReactTable from 'react-table-v6';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import ComponentCard from '../../layouts/containers/ComponentCard';
import gruposService from '../../services/http/gruposService';
import Loader from '../../layouts/loader/Loader';

const MostrarGrupos = ({ empresaId, ret }) => {
  const [grupoDataa, setGrupoDataa] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchGrupoData = async () => {
      const grupoData = await gruposService.consultarGrupos({
        headquarter: empresaId,
      });
      setGrupoDataa(grupoData);
    };
    fetchGrupoData();
  }, []);

  const handleRowDoubleClick = (row) => {
    const grupoId = row.original.codigo; // Accede al valor de la celda "codigo"
    navigate(`/grupos/detalles/${empresaId}/${grupoId}`); // Navega a la ruta deseada
  };

  const translations = {
    previousText: '<',
    nextText: '>',
    loadingText: 'Cargando..',
    ofText: 'de',
    rowsText: 'líneas',
    noDataText: 'Datos no encontrados',
    pageText: 'Página',
  };

  return (
    <>
      <ComponentCard title="Listado de grupos" hasButton={false}>
        {grupoDataa.length > 0 ? (
          <ReactTable
            {...translations}
            columns={[
              {
                Header: 'ID',
                accessor: 'codigo',
              },
              {
                Header: 'Grupo',
                accessor: 'Grupo',
              },
              {
                Header: 'Horas',
                accessor: 'horas',
              },
              {
                Header: 'Area academica',
                accessor: 'areaAcademica',
              },
              {
                Header: 'Ley educativa',
                accessor: 'leyeducativa',
              },
              {
                Header: 'Máximo alumnos',
                accessor: 'maxAlumnos',
              },
              {
                Header: 'Disponible',
                accessor: 'Disponible',
              },
              {
                Header: 'Altas',
                accessor: 'altas',
              },
              {
                Header: 'Bajas',
                accessor: 'bajas',
              },
              {
                Header: 'Estado',
                accessor: 'Estado',
              },
            ]}
            data={grupoDataa}
            defaultPageSize={10}
            showPaginationBottom
            className="-striped -highlight mt-5 text-center"
            showPageSizeOptions={false} // Deshabilita las opciones de cambio de tamaño
            getTrProps={(state, rowInfo) => ({
              onDoubleClick: () => handleRowDoubleClick(rowInfo),
            })}
          />
        ) : (
          <Loader />
        )}
        <Button className="float-left mt-4" onClick={ret}>
          Atrás
        </Button>
      </ComponentCard>
    </>
  );
};

MostrarGrupos.propTypes = {
  empresaId: PropTypes.any,
  ret: PropTypes.any,
};
export default MostrarGrupos;
